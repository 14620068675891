import React, {useEffect, useRef, useState} from 'react'
import insta from './images/insta.png'
import x from './images/X.png'
import axios from 'axios'

const Footer = () => {

  const [newsletterEmail, setNewsletterEmail] = useState({
    newsletter_email: ''
  });

  const [errorData, setErrorData] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    axios({
      method: "post",
      url: "https://scorpion-labz-53e0b0c33670.herokuapp.com/footer",
      data: JSON.stringify(newsletterEmail),
      headers: { "Content-Type": "application/JSON" }
    })
    .then((res) => {
      setErrorData(res.data);
    })
    .catch((error) => {
      setErrorData("An error occured.");
    });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setNewsletterEmail({...newsletterEmail, [name]: value});

  };

  return (
    <div className='footer-section'>
      <div className='footer-1'>
        <div className='footer-1-left'>
          <div className='footer-form'>
            <h1 className='newsletter-header'><span className='newsletter-header-slabz'>ScorpionLabz</span><br /> Quarterly Newsletter</h1>
            <form className='newsletter-form' action='/footer' method='POST' onSubmit={handleSubmit}>
              <div className='newsletter-form-email-wrapper'>
                <input name='newsletter_email' type='text' className='newsletter-form-email' placeholder='your@email.whatever' value={newsletterEmail.newsletter_email} onChange={handleInputChange}></input>
              </div>
              <div className='button-container'>
                <div className='submit-wrapper'>
                  <button type='submit' className='submit-button'><span>SIGN UP</span></button>
                </div>
              </div>
              <div className='submit-status-container'>
                {errorData && <p className='submit-good'>{errorData}</p>}
              </div>
            </form>
          </div>
        </div>
        <div className='footer-1-right'>
          <div className='footer-1-right-nav'>
            <ul className='footer-list'>
              <li className='footer-list-header'>NAVIGATE</li>
              <li className='footer-list-item'><a href='#scorpion'>home</a></li>
              <li className='footer-list-item'><a href='#services'>services</a></li>
              <li className='footer-list-item'><a href='#about'>about</a></li>
              <li className='footer-list-item'><a href='#process'>process</a></li>
              <li className='footer-list-item'><a href='#clients'>features</a></li>
              <li className='footer-list-item'><a href='#contact'>contact</a></li>
            </ul>
          </div>
        </div>
        <div className='footer-1-far-right'>
          <ul className='footer-list'>
            <li className='footer-list-header'>
              CONTACT</li>
            <li className='footer-list-item'>
              nikhil.yates@outlook.com
            </li>
            <li className='footer-list-item'>
              647 679 2497
            </li>
            <br />
            <li className='footer-list-item'>
              The 6, Canada
            </li>
          </ul>
        </div>
      </div>
      <div className='footer-2'>
        <div className='footer-2-socials'>
          <div className='footer-2-socials-icon'>
            <img src={x} className='footer-icon'></img>
          </div>
          <div className='footer-2-socials-icon'>
            <img src={insta} className='footer-icon'></img>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      {/* <div className='footer-3'>
        <div className='footer-3-credits'>
          <h1 className='footer-3-credits-h1' id='left'>Be Intentional</h1>
        </div>
        <div className='footer-3-credits'>
          <h1 className='footer-3-credits-h1' id='middle'>2024</h1>
        </div>
        <div className='footer-3-credits'>
          <h1 className='footer-3-credits-h1' id='right'>ScorpionLabz</h1>
        </div>
      </div> */}
    </div>
  )
}

export default Footer