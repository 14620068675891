import React, { useState, useEffect } from 'react';
import imgSrcDesk from './images/sLabz_white.png';
import imgSrcMobile from './images/whiteScorp_full.png'

let imgSrc = '';

if(window.innerWidth < 500) {
  imgSrc = imgSrcMobile;
}
else {
  imgSrc = imgSrcDesk;
}

const Logo = () => {

  let [scale, setScale] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const height = window.innerHeight;
      // Adjust these values as needed to control when and how the image scales
      const newScale = Math.max(1, 1 + (scrollY - height) / (height * 5));

      setScale(newScale);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='logoSect'>
      <img 
        className='logo-pict'
        src= {imgSrc}
        alt="Logo" 
        style={{ 
          transform: `scale(${scale})`, 
          transition: 'transform 0.2s ease-out',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transformOrigin: 'center center',
          transform: `translate(-50%, -50%) scale(${scale})`
        }} 
      />
    </div>
  );
};

export default Logo;
