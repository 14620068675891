import React from 'react'
import clientelle from '../data/clientSet.json'
import ch from './clients/ch.png'
import dc from './clients/dc.png'
import strive from './clients/strive.png'
import sage from './clients/sdp.png'

const Clients = () => {

    const clientz = clientelle.clientSet

    return (
        <div className='clients-sect'>
            <br />
            <div className='clients-sect-logos'>
                <div className='client-card'>
                    <div className='client-card-logo-wrapper'>
                        <img className='client-card-logo' src={ch}></img>
                    </div>
                </div>
                <div className='client-card'>
                    <div className='client-card-wrapper'>
                        <img className='client-card-logo' src={dc}></img>
                    </div>
                </div>
                <div className='client-card'>
                    <div id ='sage' className='client-card-wrapper'>
                        <img className='client-card-logo' src={sage}></img>
                    </div>
                </div>
                <div className='client-card'>
                    <div className='client-card-wrapper'>
                        <img className='client-card-logo' src={strive}></img>
                    </div>
                </div>
            </div>
            <br />
        </div>
    )
}

export default Clients