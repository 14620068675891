import React, {useEffect, useRef} from 'react'
import jsonData from '../data/services.json'

const ServiceCard = ({ service }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    const card = cardRef.current;
    const handleMouseMove = (e) => {
      const x = e.pageX - card.offsetLeft;
      const y = e.pageY - card.offsetTop;
      card.style.background = `radial-gradient(circle 100px at ${x}px ${y}px, rgba(32, 44, 244, 0.5), rgba( 73, 73, 73, 0.1 ))`;
    };

    const handleMouseOut = () => {
      card.style.background = '';
    };

    card.addEventListener('mousemove', handleMouseMove);
    card.addEventListener('mouseout', handleMouseOut);

    return () => {
      card.removeEventListener('mousemove', handleMouseMove);
      card.removeEventListener('mouseout', handleMouseOut);
    };
  }, []);

  return (
    <div ref={cardRef} className='service-card'>
      <h2 className='service-card-title'>{service.title}</h2>
      {service.subtitle && <h3 className='service-card-subtitle'>{service.subtitle}</h3>}
      <p className='service-card-description'>{service.description}</p>
    </div>
  );
};


const Services = () => {
  
  const services = jsonData.services;

  return (
    <div className='serviceSect'>
      <br />
      <div className='serviceSect-main'>

        <div className='serviceSect-main-left'>
          <div className='what-we-do'>
            <h1 className='services-title'>what<br /> we <br /> do</h1>
          </div>

        </div>
        <div className='serviceSect-main-right'>
          {services.map((service, index) => (
            <ServiceCard key={index} service={service} />
          ))}
        </div>
      </div>
      


      <div className='serviceSect-lower'>
        <div className='scorpion-button'>
          <a className='buttonName' href='#contact'><span>CONTACT</span></a>
        </div>
      </div>

    </div>
  );
}

export default Services