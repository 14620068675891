// import { logRoles } from '@testing-library/react';
import './App.css';
import { Scorpion, About, Services, Logo, Process, Clients, Contact, Footer} from './components';


const App = () => (

<div className='app'>
  <div className='mainTing'>
    <a name='scorpion'></a>
    <Scorpion />
    <a name='services'></a>
    <Services />
    <Logo />
    <a name='about'></a>
    <About />
    <a name='process'></a>
    <Process />
    <a name='clients'></a>
    <Clients />
    <a name='contact'></a>
    <Contact />
    <Footer />

  </div>
</div>
);

export default App;
