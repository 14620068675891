import React from 'react'
import design from './images/aboutGraphic.png'
import design_mobile from './images/mobile_aboutGraphic.png'
import {useEffect, useRef} from 'react'

const About = () => {

    const contactRef = useRef(null);

    useEffect(() => {
        const cont = contactRef.current;
        const handleMouseMove = (e) => {
        const x = e.pageX - cont.offsetLeft;
        const y = e.pageY - cont.offsetTop;
        cont.style.background = `radial-gradient(circle 100px at ${x}px ${y}px, rgba(32, 44, 244, 0.5), rgba( 73, 73, 73, 0.1 ))`;
        };

        const handleMouseOut = () => {
        cont.style.background = '';
        };

        cont.addEventListener('mousemove', handleMouseMove);
        cont.addEventListener('mouseout', handleMouseOut);

        return () => {
        cont.removeEventListener('mousemove', handleMouseMove);
        cont.removeEventListener('mouseout', handleMouseOut);
        };
    }, []);

    return (
        <div className='about-sect'>
            <div className='about-sect-top'>
                <div className='about-sect-title-wrapper'>
                    <h1 className='about-sect-title'>
                        ABOUT
                    </h1>
                </div>
                <div className='about-main-right-wrapper'>
                    <div ref={contactRef} className='about-sect-main' id='about-main'>
                        <h1 className='about-story-header'>
                            Our Story
                        </h1>
                        <p className='about-par'>From our inception in 2023, we've made a point to be the best. 
                            We take your business personally. We are located in Toronto with clients across the GTA and are 
                            inspired and motivated by the ever-booming growth in the city's
                            tech and arts spaces.​ The basis of our business direction and philosophy lies in our commitment to 
                            quality and personal connection with our clients. 
                            We are small business solutions, for small businesses</p>
                        <br />
                        <p className='about-par'>
                        We are all about small business and up-and-coming creatives. New company? 
                        Emerging artist? With insight from start-up, venture, and equity gurus, 
                        we've created a modular and individualized approach to building you a product 
                        that generates actual results. Check out the clients we've worked with and hit us up!
                        </p>
                    </div>
                </div>
            </div>
            <div className='about-gallery'>
                <div className='about-gallery-pic-wrapper'>
                    <img className='about-gallery-design' src={design}></img>
                </div>
            </div>
            <div className='about-gallery-mobile'>
                <div className='about-gallery-pic-mobile'>
                    <img className='about-gallery-design-mobile' src={design_mobile}></img>
                </div>
            </div>
            <div className='about-numbers'>
                <div className='about-numbers-wrapper' id='one-5'>
                    <h1 className='about-numbers-title'>
                        +150%
                    </h1>
                    <h1 className='about-numbers-subtitle'>
                        e-commerce growth for existing brands
                    </h1>
                </div>
                <div className='about-numbers-wrapper' id='inf'>
                    <h1 className='about-numbers-title'>
                        ∞
                    </h1>
                    <h1 className='about-numbers-subtitle'>
                        digital outreach potential with our 5-stage process
                    </h1>
                </div>
                <div className='about-numbers-wrapper' id='five'>
                    <h1 className='about-numbers-title'>
                        5 star
                    </h1>
                    <h1 className='about-numbers-subtitle'>
                        average review from the people we work with
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default About