import React, {useEffect, useRef, useState} from 'react'
import axios from 'axios'



const Contact = () => {

  // data to be passed to backend stored here
  const [contactData, setContactData] = useState({
    web_name: '',
    web_email: '',
    inquiry: ''
  });
 

  const [errorData, setErrorData] = useState('');

  const handleSubmit = async (e) => {
    
    e.preventDefault();

    //axios for post
    axios({
      method: "post",
      url: "https://scorpion-labz-53e0b0c33670.herokuapp.com/contact",
      data: JSON.stringify(contactData),
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      setErrorData(res.data); // Set the error message as a string
    })
    .catch((error) => {
      setErrorData("An error occurred."); // Handle any errors from the server
    });

  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setContactData({...contactData, [name]: value});

  };


  const contactRef = useRef(null);

  useEffect(() => {
    const cont = contactRef.current;
    const handleMouseMove = (e) => {
      const x = e.pageX - cont.offsetLeft;
      const y = e.pageY - cont.offsetTop;
      cont.style.background = `radial-gradient(circle 100px at ${x}px ${y}px, rgba(32, 44, 244, 0.5), rgba( 73, 73, 73, 0.1 ))`;
    };

    const handleMouseOut = () => {
      cont.style.background = '';
    };

    cont.addEventListener('mousemove', handleMouseMove);
    cont.addEventListener('mouseout', handleMouseOut);

    return () => {
      cont.removeEventListener('mousemove', handleMouseMove);
      cont.removeEventListener('mouseout', handleMouseOut);
    };
  }, []);

  return (
    <div className='contact-sect'>
      <br />
      <div ref={contactRef} className='contact-container'>
        <form className='contact-form' onSubmit={handleSubmit} method='POST' action='/contact'> 
          <div className='contact-form-title-cont'>
            <h1 className='contact-form-title'>
              let's work.
            </h1>
          </div>
          <div className='contact-form-input-cont'>
            <div className='contact-form-email-container'>
              <input name='web_name' type='text' className='contact-form-email' placeholder='your name' value={contactData.web_name} onChange={handleInputChange}></input>
            </div>
            <div className='contact-form-email-container'>
              <input name='web_email' type='text' className='contact-form-email' placeholder='your@email.whatever' value={contactData.web_email} onChange={handleInputChange}></input>
            </div>
            <div className='contact-form-email-container'>
              <textarea form='contact-form' name='inquiry' className='contact-form-email-inquiry' maxLength='500' rows='5' placeholder='some general context about your interest. you can expect a reply within the next business day.' value={contactData.inquiry} onChange={handleInputChange}></textarea>
            </div>
          </div>
          <div className='button-container'>
            <div className='submit-wrapper'>
              <button type='submit' className='submit-button'><span>SUBMIT</span></button>
            </div>
          </div>
          <div className='submit-status-container'>
            {errorData && <p className='submit-good'>{errorData}</p>}
          </div>
        </form>
      </div>
    </div>
  )
}

export default Contact