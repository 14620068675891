import React from 'react'
import process from '../data/process.json'

const Process = () => {


  const processes = process.process

  return (
    <div className='process-main'>
      <br />
      <div className='process-title-wrapper'>
        <h1 className='process-title'>THE PROCESS</h1>
      </div>
      <div className='processSect'>
        {processes.map((story, index) => (
          <div key={index} className='process-card'>

            <h1 className='process-card-id'>{story.id}</h1>

            <h2 className='process-card-title'>{story.title}</h2>
            <p className='process-card-description'>{story.description}</p>

          </div>
        ))}
      </div>
          

      <div className='scorpion-button' id='mobile-button'>
        <a className='buttonName' href='#contact'><span>LETS WORK</span></a>
      </div>
    </div>
  )
}

export default Process