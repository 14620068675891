import React, {useState} from 'react';
import scorp from './images/whiteScorp_full.png'
import axios from 'axios'
import { slide as Menu } from 'react-burger-menu'

const Scorpion = () => {




  // this first block is for form submission
  const [newsletterEmail, setNewsletterEmail] = useState({
    newsletter_email: ''
  });

  const [errorData, setErrorData] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    axios({
      method: "post",
      url: "https://scorpion-labz-53e0b0c33670.herokuapp.com/footer",
      data: JSON.stringify(newsletterEmail),
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      setErrorData(res.data);
    })
    .catch((error) => {
      setErrorData("An error occured. ", error.data);
    });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setNewsletterEmail({...newsletterEmail, [name]: value })
  };


  
  


  return (
    <div className='scorpionSect' id='scorpionSect'>
      

      {/* NAVBAR FOR DESKTOP */}
      <div className='navbar'>
        <div className='sc-wrapper'>
          <img src={scorp} className='nav-sc'></img>
        </div>
        <div className='navbar-wrapper'>
          <div className='link-wrapper'>
            <a href='#services'>services</a>
          </div>
          <div className='link-wrapper'>
            <a href='#about'>about</a>
          </div>
          <div className='link-wrapper'>
            <a href='#clients'>clients</a>
          </div>
          <div className='link-wrapper'>
            <a href='#contact'>contact</a>
          </div>
        </div>
      </div>

      {/* NAVBAR FOR MOBILE */}
      <div className='mobile-nav-wrapper'>
        <Menu>
          <a className="menu-item" href='#scorpion'>Home</a>
          <a className="menu-item" href='#services'>Services</a>
          <a className="menu-item" href='#about'>About</a>
          <a className="menu-item" href='#clients'>Clients</a>
          <a className="menu-item" href='#contact'>Contact</a>
        </Menu>
      </div>

      <div className='scorpion-main'>
        <div className='scorpion-main-title-wrapper-mobile'>
          <h1 className='scorpion-main-title-mobile'>
            we are <span className='subtitle-bold-yellow'>web</span> for the bold
          </h1>
        </div>
        <div className='scorpion-main-subtitle-wrapper'>
          <h1 className='scorpion-main-subtitle'>
            web solutions for the <span className='subtitle-bold'>bold</span>
          </h1>
        </div>
      </div>
      {/* <div className='scorpion-newsletter'>
        <br />
        <div className='scorpion-newsletter-form-wrapper'>
          <form className='scorpion-newsletter-form' action='/footer' method='POST' onSubmit={handleSubmit}>
            <div className='newsletter-form-email-wrapper'>
              <input name='newsletter_email' value={newsletterEmail.newsletter_email} onChange={handleInputChange} type='text' className='newsletter-form-email' placeholder='sign up for our newsletter'></input>
            </div>
            <div className='button-container'>
              <div className='submit-wrapper'>
                <button type='submit' className='submit-button-scorp'><span>SIGN UP</span></button>
              </div>
            </div>
            <div className='submit-status-container'>
              {errorData && <p className='submit-good'>{errorData}</p>}
            </div>
          </form>
        </div>
      </div> */}
    </div>
  );
}

export default Scorpion;
